import "./index.scss";

/**
 * Import modules
 */
import { Fancybox } from "@fancyapps/ui";
import Inputmask from "inputmask/dist/inputmask.min.js";
import lozad from "lozad";
import Swiper from "swiper/bundle";
import Toastify from "toastify-js";
import ymaps from "ymaps";

/**
 * Helpers
 */
const cyrValue = (str) => {
  return /[^а-яА-ЯёЁ -]/gi.test(str);
};
const fancyboxShow = (src, type) => {
  Fancybox.show(
    [
      {
        src: src,
        type: type,
        autoFocus: false,
        trapFocus: false,
        placeFocusBack: false,
      },
    ],
    {
      on: {
        done: (fancybox, slide) => {
          if (slide.$content.querySelector(".product-slider")) {
            let slider = slide.$content.querySelector(".product-slider"),
              next = slider.querySelector(".next"),
              prev = slider.querySelector(".prev"),
              scrollbar = slider.querySelector(".scrollbar"),
              swiper = new Swiper(slider, {
                navigation: {
                  nextEl: next,
                  prevEl: prev,
                },
                scrollbar: {
                  el: scrollbar,
                },
              });
          }
        },
      },
    }
  );
};
const toast = (title = "", text = "", type = "info") => {
  let message, messageTitle, messageText;
  message = document.createElement("div");
  messageTitle = document.createElement("p");
  messageTitle.classList.add("title");
  messageTitle.innerHTML = title;
  messageText = document.createElement("p");
  messageText.classList.add("text");
  messageText.innerHTML = text;
  message.append(messageTitle, messageText);

  Toastify({
    className: type,
    close: true,
    destination: false,
    duration: 3000,
    escapeMarkup: false,
    gravity: "bottom",
    position: "right",
    stopOnFocus: true,
    text: message.innerHTML,
    onClick: () => {},
  }).showToast();
};
const scrollSpy = (selector) => {
  let sections = document.querySelectorAll(selector),
    sectionsObj = {},
    i = 0;

  sections.forEach((e) => {
    sectionsObj[e.id] = e.offsetTop;
  });

  window.onscroll = () => {
    let scrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop;

    for (i in sectionsObj) {
      if (sectionsObj[i] <= scrollPosition) {
        document.querySelector(".active").setAttribute("class", "");
        document
          .querySelector("a[href*=" + i + "]")
          .setAttribute("class", "active");
      }
    }
  };
};
const scrollTo = (target) => {
  document
    .querySelector(target)
    .scrollIntoView({ block: "start", behavior: "smooth" });
};
const addModalEvent = (selector) => {
  document.querySelectorAll(selector).forEach((el) => {
    if (el.classList.contains("modal-rendered") == false) {
      el.classList.add("modal-rendered");

      el.addEventListener("click", (evt) => {
        evt.preventDefault();

        const button = evt.currentTarget,
          target = button.hash || button.dataset.href,
          popup = document.querySelector(target),
          title = button.dataset.modalTitle,
          content = button.dataset.modalContent,
          footer = button.dataset.modalFooter,
          submit = button.dataset.modalSubmit,
          yaGoal = button.dataset.modalYagoal,
          email = button.dataset.modalEmail,
          saleDescr = button.dataset.modalSaleDescr;

        if (title) popup.querySelector(".modal__title span").innerHTML = title;
        if (submit) popup.querySelector("input[type='submit']").value = submit;
        if (yaGoal) popup.querySelector("input[name='ya_goal']").value = yaGoal;
        if (email)
          popup.querySelector("input[name='email_title']").value = email;
        if (target) fancyboxShow(target, "inline");
        if (saleDescr)
          popup.querySelector(".sales-modal__descr").innerHTML = saleDescr;
      });
    }
  });
};
const inputValidate = (selector) => {
  document.querySelectorAll(selector).forEach((el) => {
    if (el.classList.contains("validate-rendered") == false) {
      el.classList.add("validate-rendered");

      if (selector === "input[name='agreement']") {
        el.addEventListener("change", () => {
          const status = el.checked;

          if (status) {
            el.closest("form")
              .querySelector("input[type='submit']")
              .removeAttribute("disabled");
            el.closest("form")
              .querySelector(".form__submit")
              .classList.remove("disabled");
          } else {
            el.closest("form")
              .querySelector("input[type='submit']")
              .setAttribute("disabled", "");
            el.closest("form")
              .querySelector(".form__submit")
              .classList.add("disabled");
          }
        });
      }
      if (selector === "input[name='name']") {
        let counter = 0;

        el.addEventListener("keyup", () => {
          if (cyrValue(el.value)) {
            counter++;

            if (counter > 5) {
              toast("Внимание!", "Имя должно быть на русском языке", "warning");
              counter = 0;
            }
          }

          el.value = el.value.replace(/[^а-яА-ЯёЁ -]/gi, "");
        });
        el.addEventListener("blur", () => {
          if (el.value.length > 0) {
            el.parentNode.classList.add("valid");
          } else {
            el.parentNode.classList.remove("valid");
          }
        });
      }
      if (selector === "input[name='phone']") {
        let im = new Inputmask({
          mask: "+7 (999) 999-99-99",
          definitions: {
            e: {
              validator: "[0-7,9]",
            },
          },
        });
        im.mask(el);

        el.addEventListener("blur", () => {
          if (el.value[17] == "_" || el.value[17] == undefined) {
            toast("Внимание!", "Некорректный номер", "warning");
            el.parentNode.classList.add("error");
            el.parentNode.classList.remove("valid");
          } else {
            el.parentNode.classList.add("valid");
            el.parentNode.classList.remove("error");
          }
        });
      }
      if (selector === "input[name='file_attach[]']") {
        el.addEventListener("change", () => {
          let totalSize = 0;
          Object.keys(e.currentTarget.files).map((objectKey, index) => {
            totalSize = totalSize + el.files[index].size;
          });
          if (Math.round((totalSize / 1048576) * 100) / 100 > 10) {
            el.value = "";
            toast(
              "Внимание!",
              "Максимальный объём вложений - 10 мб.",
              "warning"
            );
          } else if (el.files.length > 10) {
            el.value = "";
            toast(
              "Внимание!",
              "Максимальное количество вложений - 10 шт.",
              "warning"
            );
          } else {
            el.closest("form").querySelector("#file-name").innerText =
              el.files.length +
              " шт. (" +
              Math.round((totalSize / 1048576) * 100) / 100 +
              " мб.)";
            el.closest("form")
              .querySelector("p.filename")
              .classList.add("is-loaded");
          }
        });
        el.closest("form")
          .querySelector("#file-clear")
          .addEventListener("click", (evt) => {
            el.value = "";
            evt.currentTarget
              .closest("form")
              .querySelector("p.filename")
              .classList.remove("is-loaded");
            document.querySelector("#file-name").innerText = "";
          });
      }
      if (
        selector === "input[type='email']" ||
        selector === "input[type='text']" ||
        selector === "textarea"
      ) {
        el.addEventListener("blur", () => {
          if (el.value.length > 0) {
            el.parentNode.classList.add("valid");
          } else {
            el.parentNode.classList.remove("valid");
          }
        });
      }
      if (selector === "input[required]") {
        el.addEventListener("blur", () => {
          if (el.value.length == 0) {
            toast("Внимание!", "Поле обязательно для заполнения", "warning");
          }
        });
      }
    }
  });
};

const getNews = async (params, button) => {
  let response = await fetch(
      wp_data.fetch_url + "?" + new URLSearchParams(params).toString()
    ),
    result = await response.json();

  if (Number(result.count) === 12) {
    button.setAttribute("data-page", params.page + 1);
    toast("Внимание!", "Новости загружены", "info");
  } else {
    button.remove();
    toast("Внимание!", "Все новости загружены", "info");
  }
  if (Number(result.count) > 0) {
    document
      .querySelector("#category-list")
      .insertAdjacentHTML("beforeend", result.html);
    observer.observe();
  }
};

/**
 * Animated elements
 */
const animations_observer = lozad(".lazy-animation", {
  loaded: (el) => {
    let clientWidth = document.body.clientWidth,
      delay = (Number(el.dataset.delay) - 1) / 2;

    el.classList.add("animate__animated", "animate__fadeInUp");

    if (clientWidth > 415) {
      el.style.setProperty("animation-delay", delay + "s");
    }
  },
});
animations_observer.observe();

/**
 * Fields validation
 */
if (document.querySelector("input[name='agreement']"))
  inputValidate("input[name='agreement']");
if (document.querySelector("input[name='name']"))
  inputValidate("input[name='name']");
if (document.querySelector("input[name='phone']"))
  inputValidate("input[name='phone']");
if (document.querySelector("input[required]")) inputValidate("input[required]");
if (document.querySelector("input[type='text']"))
  inputValidate("input[type='text']");
if (document.querySelector("input[type='email']"))
  inputValidate("input[type='email']");
if (document.querySelector("textarea")) inputValidate("textarea");
// if (document.querySelector("input[name='file_attach[]']"))
//   inputValidate("input[name='file_attach[]']");

if (document.querySelector('input[name="file_attach[]"]')) {
  document.querySelectorAll('input[name="file_attach[]"]').forEach((el) => {
    el.addEventListener("change", (e) => {
      let totalSize = 0;
      Object.keys(e.currentTarget.files).map((objectKey, index) => {
        totalSize = totalSize + e.currentTarget.files[index].size;
      });
      if (Math.round((totalSize / 1048576) * 100) / 100 > 10) {
        el.value = "";
        alertify.notify(
          "Внимание! <br> Максимальный объём вложений - 10 мб.",
          "warning",
          5
        );
      } else if (e.currentTarget.files.length > 10) {
        el.value = "";
        alertify.notify(
          "Внимание! <br> Максимальное количество вложений - 10 шт.",
          "warning",
          5
        );
      } else {
        e.currentTarget.closest("form").querySelector("#file-name").innerText =
          e.currentTarget.files.length +
          " шт. (" +
          Math.round((totalSize / 1048576) * 100) / 100 +
          " мб.)";
        e.currentTarget
          .closest("form")
          .querySelector("p.filename")
          .classList.add("is-loaded");
      }
    });
    el.closest("form")
      .querySelector("#file-clear")
      .addEventListener("click", (e) => {
        el.value = "";
        e.currentTarget
          .closest("form")
          .querySelector("p.filename")
          .classList.remove("is-loaded");
        document.querySelector("#file-name").innerText = "";
      });
  });
}

/**
 * Form
 */

if (document.querySelector("form.fetch")) {
  document.querySelectorAll("form.fetch").forEach((form) => {
    form.addEventListener("submit", (evt) => {
      evt.preventDefault();
      let yaGoal = form.querySelector("input[name='ya_goal']").value,
      phone = form.querySelector("input[name='phone']").value,
      yaMetrikaId = wp_data.ya_metrika_id,
      yaMetrikaGoal = wp_data.ya_metrika_goal_id;

      if (phone.length[17] === "_" || phone.length === 0) {
        document.querySelector("input[name='phone']").classList.add("is-error");
      } else {
        fancyboxShow("#loading", "inline");
        if (form.querySelector("input[type='submit']"))
          form
            .querySelector("input[type='submit']")
            .setAttribute("disabled", "");
        if (form.querySelector("button[type='submit']"))
          form
            .querySelector("button[type='submit']")
            .setAttribute("disabled", "");

        let data = new FormData(form);
        mna100("email", data, (r) => {
          if (r.status === 1) {
            Fancybox.close();

            if (form.querySelector("input[type='submit']"))
              form
                .querySelector("input[type='submit']")
                .removeAttribute("disabled", "");
            if (form.querySelector("button[type='submit']"))
              form
                .querySelector("button[type='submit']")
                .removeAttribute("disabled", "");

            form.querySelectorAll("input").forEach((inp) => {
              if (
                inp.type !== "submit" &&
                inp.type !== "checkbox" &&
                inp.type !== "radio" &&
                inp.type !== "hidden"
              ) {
                inp.value = "";
                inp.parentNode.classList.remove("valid");
              }
            });
            if (form.querySelector("textarea")) {
              form.querySelector("textarea").value = "";
              form
                .querySelector("textarea")
                .parentNode.classList.remove("valid");
            }
            if (form.querySelector("input[type='checkbox']")) {
              form.querySelectorAll("input[type='checkbox']").forEach((el) => {
                if (el.name != "agreement") el.checked = false;
              });
            }

            setTimeout(() => {
              fancyboxShow("#thanks", "inline");
            }, 150);
            setTimeout(() => {
              Fancybox.close();
            }, 5000);

            if (typeof ym === "function" && yaMetrikaId) {
              let goal = yaMetrikaGoal ? yaMetrikaGoal : yaGoal;
              ym(yaMetrikaId, "reachGoal", goal);
              console.log("Цель достигнута: " + goal);
            }
            if (typeof gtag === "function") {
              gtag("event", "form_lead", {
                event_category: "lead",
                event_action: "zayavka",
              });
            }
          } else {
            toast("Внимание!", "Ошибка! " + r.message, "error");

            Fancybox.close();
            form
              .querySelector("input[type='submit']")
              .removeAttribute("disabled", "");
          }
        });
      }
    });
  });
}

/**
 * Lazy load
 */
const observer = lozad();
observer.observe();

/**
 * Mobile menu
 */
if (document.querySelector("button.hamburger")) {
  document
    .querySelector("button.hamburger")
    .addEventListener("click", (evt) => {
      let buttonClasslist = evt.currentTarget.classList;

      buttonClasslist.toggle("active");
      if (buttonClasslist.contains("active")) {
        document.querySelector(".header__mobile").classList.add("active");
        document.querySelector(".header__mask").classList.add("active");
        document.querySelector("body").style.overflow = "hidden";
        document.querySelector(".crutche").classList.add("active");
      } else {
        document.querySelector(".header__mobile").classList.remove("active");
        document.querySelector(".header__mask").classList.remove("active");
        document.querySelector("body").style = "";
      }
    });
  document.querySelector(".header__mask").addEventListener("click", () => {
    document.querySelector("button.hamburger").classList.remove("active");
    document.querySelector(".header__mobile").classList.remove("active");
    document.querySelector(".header__mask").classList.remove("active");
    document.querySelector("body").style = "";
  });
}
if (document.querySelector(".menu-item-has-children")) {
  document.querySelectorAll(".menu-item-has-children").forEach((i) => {
    i.addEventListener("click", () => {
      i.classList.toggle("active");
    });
  });
}

if (document.querySelector("button.crutche")) {
  document.querySelector("button.crutche").addEventListener("click", (evt) => {
    document.querySelector(".header__mobile").classList.remove("active");
    document.querySelector(".header__mask").classList.remove("active");
    document.querySelector(".hamburger-btn-header").classList.remove("active");
    document.querySelector(".crutche").classList.remove("active");
    document.querySelector("body").style = "";
  });
}

/**
 * Modal
 */
if (document.querySelector(".modal-link")) addModalEvent(".modal-link");

/**
 * Products load more
 */
if (document.querySelector("#news-load-more")) {
  const button = document.querySelector("#news-load-more");
  button.addEventListener("click", (evt) => {
    evt.preventDefault();

    const cat = button.dataset.cat,
      page = button.dataset.page,
      offset = button.dataset.offset,
      params = {
        action: "get_products",
        cat: cat,
        page: Number(page),
        offset: Number(offset),
      };

    getNews(params, button);
  });
}

/**
 * Scroll spy init
 */
if (document.querySelector(".scroll-spy")) {
  //scrollSpy('.scroll-spy');
}

if (document.querySelector(".js-show-more")) {
  document.querySelectorAll(".js-show-more").forEach((el) => {
    el.addEventListener("click", (evt) => {
      showMoreElements(evt);
    });
  });
}

/* Show more elements */
function showMoreElements(evt) {
  /*
  @ Parameters:
    evt — object

  @ Description: hooks up a listener to all show-more buttons, which shows all
  elements when click on button.

  @ Return: null
  */

  document
    .querySelectorAll(".js-show-more-elements-container > *")
    .forEach((el) => {
      if (!el.classList.contains("active")) el.classList.add("active");
    });
  evt.currentTarget.classList.remove("active");
}

/**
 * Show more about text
 */
if (document.querySelector(".main__about__btn button")) {
  const btn_more_client = document.querySelector(".main__about__btn button");
  btn_more_client.addEventListener("click", (evt) => {
    evt.preventDefault();

    if (
      document.querySelector(".main__about__content").classList.contains("show")
    ) {
      document.querySelector(".main__about__content").classList.remove("show");
      document.querySelector(".main__about__btn button").textContent =
        "Показать полностью";
    } else {
      document.querySelector(".main__about__content").classList.add("show");
      document.querySelector(".main__about__btn button").textContent =
        "Скрыть текст";
    }
  });
}

/**
 * Swiper channel prices
 */
if (document.querySelector(".swiper.channel-prices-desktop")) {
  new Swiper(".swiper.channel-prices-desktop", {
    scrollbar: {
      draggable: true,
      el: ".swiper-scrollbar.channel-prices-desktop",
      hide: true,
    },
    slidesPerView: "auto",
  });
}

/**
 * Swiper prices table
 */
if (document.querySelector(".swiper.prices-table-desktop")) {
  new Swiper(".swiper.prices-table-desktop", {
    scrollbar: {
      draggable: true,
      el: ".swiper-scrollbar.prices-table-desktop",
      hide: true,
    },
    slidesPerView: "auto",
  });
  new Swiper(".swiper.prices-table-mobile", {
    scrollbar: {
      draggable: true,
      el: ".swiper-scrollbar.prices-table-mobile",
      hide: true,
    },
    slidesPerView: "auto",
  });
}

/**
 * Swiper records
 */
if (document.querySelector(".swiper.about-records")) {
  new Swiper(".swiper.about-records", {
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      415: {
        slidesPerView: 2,
      },
      991: {
        slidesPerView: 3,
      },
    },
    scrollbar: {
      draggable: true,
      el: ".swiper-scrollbar.about-records",
      hide: false,
    },
    spaceBetween: 10,
  });
}

/**
 * Swiper banner advantages
 */
if (document.querySelector(".swiper.main-advantages")) {
  new Swiper(".swiper.main-advantages", {
    pagination: {
      el: ".swiper-pagination.main__banner__advantages__slider__pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl:
        ".swiper-button-next.main__banner__advantages__slider__pagination-next",
      prevEl:
        ".swiper-button-prev.main__banner__advantages__slider__pagination-prev",
    },
    slidesPerView: "auto",
    scrollbar: {
      draggable: true,
    },
    spaceBetween: 10,
  });
}

/**
 * Swiper home advantages
 */
if (document.querySelector(".swiper.advantages-slider")) {
  new Swiper(".swiper.advantages-slider", {
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      991: {
        slidesPerView: 3,
      },
    },
    scrollbar: {
      draggable: true,
    },
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination.advantages-slider__pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next.advantages-slider-next",
      prevEl: ".swiper-button-prev.advantages-slider-prev",
    },
  });
}

/**
 * Swiper full testimonials
 */
if (document.querySelector(".swiper.testimonials-full-slider")) {
  new Swiper(".swiper.testimonials-full-slider", {
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      1100: {
        slidesPerView: 2,
      },
    },
    spaceBetween: 10,
    pagination: {
      el: ".swiper-pagination.testimonials-full-slider",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next.swiper-button-next-testimonials-full",
      prevEl: ".swiper-button-prev.swiper-button-next-testimonials-full",
    },
  });
}

/**
 * Swiper testimonials
 */
if (document.querySelector(".swiper.testimonials-slider")) {
  new Swiper(".swiper.testimonials-slider", {
    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
      },
      1100: {
        slidesPerView: 5,
      },
    },
    spaceBetween: 10,
    pagination: {
      el: ".swiper-pagination.testimonials-slider",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next.swiper-button-next-testimonials",
      prevEl: ".swiper-button-prev.swiper-button-next-testimonials",
    },
  });
}

/**
 * Swiper testimonials 2009
 */
if (document.querySelector(".swiper.testimonials-slider2009")) {
  new Swiper(".swiper.testimonials-slider2009", {
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: ".swiper-pagination.testimonial2009_pagination",
      dynamicBullets: true,
      type: "bullets",
      loop: true,
      clickable: true,
    },
    navigation: {
      nextEl: ".testimonials-slider2009_prev",
      prevEl: ".testimonials-slider2009_next",
    },
  });
}

/**
 * Swiper testimonials 2016
 */
if (document.querySelector(".swiper.testimonials-slider2016")) {
  new Swiper(".swiper.testimonials-slider2016", {
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: ".swiper-pagination.testimonial2016_pagination",
      dynamicBullets: true,
      type: "bullets",
      loop: true,
      clickable: true,
    },
    navigation: {
      nextEl: ".testimonials-slider2016_prev",
      prevEl: ".testimonials-slider2016_next",
    },
  });
}

/**
 * Swiper testimonials 2021
 */
if (document.querySelector(".swiper.testimonials-slider2021")) {
  new Swiper(".swiper.testimonials-slider2021", {
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: ".swiper-pagination.testimonial2021_pagination",
      dynamicBullets: true,
      type: "bullets",
      loop: true,
      clickable: true,
    },
    navigation: {
      nextEl: ".testimonials-slider2021_prev",
      prevEl: ".testimonials-slider2021_next",
    },
  });
}

/**
 * Swiper publicaion
 */
if (document.querySelector(".swiper-container.about__publication")) {
  new Swiper(".swiper-container.about__publication", {
    autoHeight: true,
    grabCursor: true,
    spaceBetween: 20,
    pagination: {
      el: ".swiper-pagination.about__publication",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next.about__publication",
      prevEl: ".swiper-button-prev.about__publication",
    },
  });
}

/**
 * Swiper publication - video
 */
if (
  document.querySelector(".swiper-container.common__video-presentation--swiper")
) {
  new Swiper(".swiper-container.common__video-presentation--swiper", {
    autoHeight: true,
    grabCursor: true,
    // spaceBetween: 20,
    pagination: {
      el: ".swiper-pagination.common__video-presentation--swiper",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next.common__video-presentation--swiper",
      prevEl: ".swiper-button-prev.common__video-presentation--swiper",
    },
  });
}

/**
 * SMM SLIDER
 */
if (document.querySelector(".smm__part__slider")) {
  document.querySelectorAll(".smm__part__slider").forEach((slider) => {
    new Swiper(slider, {
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: slider.querySelector(".swiper-pagination"),
        type: "bullets",
        clickable: true,
      },
    });
  });
}

/*
if (document.querySelector('.swiper.smm__part__slider')) {
  new Swiper('.swiper.smm__part__slider', {
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination.smm__part__slider__pagination',
      type: 'bullets',
      clickable: true,
    },
  });
}
*/

/**
 * Online opportunities slider
 */
if (document.querySelector(".online-opportunities-slider")) {
  new Swiper(".swiper.online-opportunities-slider", {
    autoHeight: true,
    pagination: {
      el: ".swiper-pagination.online-opportunities-slider",
      type: "bullets",
      clickable: true,
    },
    slidesPerView: 1,
    spaceBetween: 10,
  });
}

/**
 * Online yandex slider
 */
if (document.querySelector(".online-yandex-slider")) {
  new Swiper(".swiper.online-yandex-slider", {
    autoHeight: true,
    pagination: {
      el: ".swiper-pagination.online-yandex-slider",
      type: "bullets",
      clickable: true,
    },
    slidesPerView: 1,
    spaceBetween: 10,
  });
}

/**
 * Online places slider
 */
if (document.querySelector(".online-places-slider")) {
  new Swiper(".swiper.online-places-slider", {
    autoHeight: true,
    pagination: {
      el: ".swiper-pagination.online-places-slider",
      type: "bullets",
      clickable: true,
    },
    slidesPerView: 1,
    spaceBetween: 10,
  });
}

/**
 * Online statistics slider
 */
if (document.querySelector(".online-statistics-slider")) {
  new Swiper(".swiper.online-statistics-slider", {
    autoHeight: true,
    pagination: {
      el: ".swiper-pagination.online-statistics-slider",
      type: "bullets",
      clickable: true,
    },
    slidesPerView: 1,
    spaceBetween: 10,
  });
}

/**
 * Online myTarget slider
 */
if (document.querySelector(".online-mytarget-slider")) {
  new Swiper(".swiper.online-mytarget-slider", {
    autoHeight: true,
    pagination: {
      el: ".swiper-pagination.online-mytarget-slider",
      type: "bullets",
      clickable: true,
    },
    slidesPerView: 1,
    spaceBetween: 10,
  });
}

/**
 * Swiper tv-advert-example
 */
if (document.querySelector(".swiper.tv-advert-slider")) {
  new Swiper(".swiper.tv-advert-slider", {
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      991: {
        slidesPerView: 3,
      },
    },
    scrollbar: {
      draggable: true,
    },
    scrollbar: {
      draggable: true,
      el: ".swiper-scrollbar.common__tv-advert-slider__drag",
      hide: false,
    },
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination.common__tv-advert-slider__pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next.common__tv-advert-slider-next",
      prevEl: ".swiper-button-prev.common__tv-advert-slider-prev",
    },
  });
}

/**
 * Swiper video-shooting 2d
 */
if (document.querySelector(".shooting__wrapper__list__item__swiper--2d")) {
  new Swiper(".shooting__wrapper__list__item__swiper--2d", {
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      991: {
        slidesPerView: 1,
      },
    },
    spaceBetween: 30,
    pagination: {
      el: ".shooting__wrapper__list__item__pagination--2d",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl:
        ".swiper-button-next.shooting__wrapper__list__item__btns--next--2d",
      prevEl:
        ".swiper-button-prev.shooting__wrapper__list__item__btns--prev--2d",
    },
  });
}

/**
 * Swiper video-shooting 3d
 */
if (document.querySelector(".shooting__wrapper__list__item__swiper--3d")) {
  new Swiper(".shooting__wrapper__list__item__swiper--3d", {
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      991: {
        slidesPerView: 1,
      },
    },
    spaceBetween: 30,
    pagination: {
      el: ".shooting__wrapper__list__item__pagination--3d",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl:
        ".swiper-button-next.shooting__wrapper__list__item__btns--next--3d",
      prevEl:
        ".swiper-button-prev.shooting__wrapper__list__item__btns--prev--3d",
    },
  });
}

/**
 * Swiper video-shooting light
 */
if (document.querySelector(".shooting__wrapper__list__item__swiper--light")) {
  new Swiper(".shooting__wrapper__list__item__swiper--light", {
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      991: {
        slidesPerView: 1,
      },
    },
    spaceBetween: 30,
    pagination: {
      el: ".shooting__wrapper__list__item__pagination--light",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl:
        ".swiper-button-next.shooting__wrapper__list__item__btns--next--light",
      prevEl:
        ".swiper-button-prev.shooting__wrapper__list__item__btns--prev--light",
    },
  });
}

/**
 * Swiper video-shooting premium
 */
if (document.querySelector(".shooting__wrapper__list__item__swiper--premium")) {
  new Swiper(".shooting__wrapper__list__item__swiper--premium", {
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      991: {
        slidesPerView: 1,
      },
    },
    spaceBetween: 30,
    pagination: {
      el: ".shooting__wrapper__list__item__pagination--premium",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl:
        ".swiper-button-next.shooting__wrapper__list__item__btns--next--premium",
      prevEl:
        ".swiper-button-prev.shooting__wrapper__list__item__btns--prev--premium",
    },
  });
}

/**
 * Ymaps
 */
if (document.querySelector("#contacts-map")) {
  const maps_observer = lozad(".map-lazy", {
    loaded: (el) => {
      const coords = el.dataset.coords.split(", "),
        apiKey = el.dataset.key,
        icon = el.dataset.icon,
        label = el.dataset.label,
        lineCoords = el.dataset.line,
        lineArr = [],
        clientWidth = document.body.clientWidth;

      let center = coords;
      if (clientWidth > 1199) {
        center = [Number(coords[0]), Number(coords[1]) - 0.035];
      } else if (clientWidth < 992) {
        center = [Number(coords[0]) + 0.025, Number(coords[1])];
      }

      lineCoords.split(";").forEach((el) => {
        let coordsArr = [];
        el.split(",").forEach((coord) => {
          coordsArr.push(parseFloat(coord));
        });

        lineArr.push(coordsArr);
      });

      ymaps
        .load("https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=" + apiKey)
        .then((maps) => {
          const myMap = new maps.Map("contacts-map", {
              center: center,
              zoom: 13,
              controls: ["fullscreenControl", "zoomControl"],
            }),
            myPlacemark = new maps.Placemark(
              coords,
              {
                hintContent: label,
                balloonContent: label,
              },
              {
                iconLayout: "default#image",
                iconImageHref: icon,
                iconImageSize: [100, 100],
                iconImageOffset: [-50, -100],
              }
            ),
            myPolyline = new maps.Polyline(
              lineArr,
              {
                balloonContent: "Схема прохода",
              },
              {
                balloonCloseButton: true,
                strokeColor: "#FF6332",
                strokeWidth: 3,
                strokeOpacity: 0.85,
              }
            );
          myMap.behaviors.disable("scrollZoom");
          myMap.geoObjects.add(myPlacemark);
          myMap.geoObjects.add(myPolyline);
        });
    },
  });
  maps_observer.observe();
}

/**
 * Youtube render
 */
if (document.querySelector("img.video__frame")) {
  let players = {};
  document.querySelectorAll("img.video__frame").forEach((img) => {
    img.addEventListener("click", () => {
      function onYouTubeIframeAPIReady() {
        players[img.id] = new YT.Player(img.id, {
          height: "250",
          width: "100%",
          videoId: img.id,
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        });
      }
      function onPlayerReady(event) {
        event.target.playVideo();
      }
      function onPlayerStateChange(event) {}

      onYouTubeIframeAPIReady();
    });
  });
}

const moveYandexRating = (selector) => {
  document.querySelector(selector).style.bottom = "7.5rem";
};

const showBtns = (selector) => {
  document.querySelector(selector).classList.add("active");
};

/* Footer social btn */
if (document.querySelector(".footer__social")) {
  setTimeout(() => {
    showBtns(".footer__social");
    moveYandexRating(".yandex-rating");
  }, 5000);
}

/* Cookies */
function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

let dataLazyLoadingJS = {
  data: {
    yaCounter1: {
      status: false,
      metricaType: "yandex",
      id: 42391829,
      area: "body",
    },
  },
};

if (wp_data.ya_metrika_id) {
  dataLazyLoadingJS.data.yaCounter2 = {
    status: false,
    metricaType: "yandex",
    id: wp_data.ya_metrika_id,
    area: "body",
  };
}

let dataSettings = {
  cookie_name: "PST_VERIFIED_COOKIE",
  fancybox: {
    src: "#popup-benefit",
    type: "inline",
  },
};

class PtsLazyLoad {
  constructor(dataLazyLoadingJS, dataSettings) {
    if (typeof PtsLazyLoad.instance == "object") return PtsLazyLoad.instance;

    PtsLazyLoad.instance = this;

    this.dataLazyLoadingJS = dataLazyLoadingJS;
    this.dataSettings = dataSettings;

    if (!this.cookieIsSet() && !this.isSearchSystemBotSigns()) {
      this.showMessage();
    } else {
      this.loadAllDataScripts();
    }

    return this;
  }

  lazyLoadingJS(type, area) {
    if (this.dataLazyLoadingJS["data"][type]["status"] === false) {
      this.dataLazyLoadingJS["data"][type]["status"] = true;

      const id = this.dataLazyLoadingJS["data"][type]["id"];
      const metricaType = this.dataLazyLoadingJS["data"][type]["metricaType"];

      if (metricaType == "yandex") {
        (function (m, e, t, r, i, k, a) {
          m[i] =
            m[i] ||
            function () {
              (m[i].a = m[i].a || []).push(arguments);
            };
          m[i].l = 1 * new Date();
          (k = e.createElement(t)),
            (a = e.getElementsByTagName(t)[0]),
            (k.async = 1),
            (k.src = r),
            a.parentNode.insertBefore(k, a);
        })(
          window,
          document,
          "script",
          "https://mc.yandex.ru/metrika/tag.js",
          "ym"
        );
        ym(id, "init", {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        });

        document
          .querySelector(area)
          .insertAdjacentHTML(
            "beforeend",
            `<!-- Yandex.Metrika counter --><noscript><div><img src='https://mc.yandex.ru/watch/${id}' style='position:absolute; left:-9999px;' alt='' /></div></noscript> <!-- /Yandex.Metrika counter -->`
          );
      }
    }
  }

  loadAllDataScripts() {
    for (const property in this.dataLazyLoadingJS["data"]) {
      this.lazyLoadingJS(
        property,
        this.dataLazyLoadingJS["data"][property]["area"]
      );
    }
  }

  showMessage() {
    Fancybox.show(
      [
        {
          src: this.dataSettings.fancybox.src,
          type: this.dataSettings.fancybox.type,
        },
      ],
      {
        on: {
          closing: () => {
            this.setCookie();
            this.loadAllDataScripts();
          },
        },
      }
    );
  }

  isSearchSystemBotSigns() {
    let uaList = [
      "APIs-Google",
      "Mediapartners-Google",
      "AdsBot-Google-Mobile",
      "AdsBot-Google",
      "Googlebot",
      "AdsBot-Google-Mobile-Apps",
      "YandexBot",
      "YandexMobileBot",
      "YandexDirectDyn",
      "YandexScreenshotBot",
      "YandexImages",
      "YandexVideo",
      "YandexVideoParser",
      "YandexMedia",
      "YandexBlogs",
      "YandexFavicons",
      "YandexWebmaster",
      "YandexPagechecker",
      "YandexImageResizer",
      "YandexAdNet",
      "YandexDirect",
      "YaDirectFetcher",
      "YandexCalendar",
      "YandexSitelinks",
      "YandexMetrika",
      "YandexNews",
      "YandexCatalog",
      "YandexMarket",
      "YandexVertis",
      "YandexForDomain",
      "YandexSpravBot",
      "YandexSearchShop",
      "YandexMedianaBot",
      "YandexOntoDB",
      "YandexOntoDBAPI",
      "YandexVerticals",
      "Mail.RU_Bot",
      "StackRambler",
      "Yahoo",
      "msnbot",
      "bingbot",
      "PixelTools",
      "PixelBot",
    ];
    let sBrowser = false,
      sUsrAg = navigator.userAgent;
    for (let i = 0; i < uaList.length; i += 1) {
      if (sUsrAg.indexOf(uaList[i]) > -1) {
        sBrowser = true;
        break;
      }
    }
    return sBrowser;
  }

  cookieIsSet() {
    return getCookie(this.dataSettings.cookie_name) !== null;
  }

  setCookie() {
    setCookie(this.dataSettings.cookie_name, true, 1);
  }
}

let LazyLoad = new PtsLazyLoad(dataLazyLoadingJS, dataSettings);

if (document.querySelector(".popup-benefit__continue-button")) {
  document
    .querySelector(".popup-benefit__continue-button")
    .addEventListener("click", (e) => {
      LazyLoad.setCookie();
      LazyLoad.loadAllDataScripts();
      Fancybox.close();
    });
}
